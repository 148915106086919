<template>
  <div
    class="dropzone"
    :class="{'dropzone--invalid': error}"
  >
    <p v-if="countSelectedPhotos" class="dropzone__title">
      Загруженные фотографии
      <span class="dropzone__count">
        {{ countSelectedPhotos }}/{{ maxCountPhoto }}
      </span>
    </p>
    <div class="dropzone__content">
      <div
        v-for="photo in uploadedPhotos"
        :key="photo.id || photo"
        class="dropzone__preview"
      >
        <div class="dropzone__image">
          <CustomImage
            v-if="photo.id"
            :image-id="photo.id"
            :alt="photo.original_name"
            :width="imageSizes[0]"
            :height="imageSizes[1]"
          />
        </div>
        <button
          class="dropzone__remove"
          aria-label="Удалить фото"
          @click="removePhoto(photo.id || photo)"
        />
      </div>
      <dropzone
        id="dropzone"
        ref="dropzone"
        :options="optionsDropzone"
        :destroy-dropzone="false"
        :include-styling="false"
        use-custom-slot
      >
        <div v-if="countSelectedPhotos < maxCountPhoto" class="dropzone__add-photos">
          <span class="dropzone__mask" />
          <p v-show="!countSelectedPhotos" class="dropzone__text font font_m">
            До 5 фотографий в&nbsp;формате png, jpeg
          </p>
        </div>
      </dropzone>
    </div>
    <p v-if="error" class="dropzone__message">
      {{ error }}
    </p>
  </div>
</template>

<script>
import Dropzone from 'nuxt-dropzone';
import './index.css';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ReturnUploadPhotos',
  components: {
    CustomImage,
    Dropzone,
  },
  props: {
    uploadedPhotos: {
      type: Array,
      default() {
        return [];
      },
    },
    error: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countSelectedPhotos: this.uploadedPhotos.length,
      maxCountPhoto: 5,
      optionsDropzone: {
        url: '/v1/lk/returns/add-photo',
        maxFilesize: 20,
        acceptedFiles: 'image/*',
        dictFileTooBig: 'Превышен максимальный размер файла: {{maxFilesize}}Мб.',
        dictMaxFilesExceeded : 'Превышено максимальное количество',
        previewTemplate: this.template(),
        processing: () => {
          this.countSelectedPhotos++;
        },
        maxfilesexceeded: (file) => {
          if (file.previewElement != null && file.previewElement.parentNode != null) {
            file.previewElement.parentNode.removeChild(file.previewElement);
          }
          this.countSelectedPhotos--;
        },
        error: (file, errorMessage) => {
          const dzErrorMessage = file.previewElement.querySelector('[data-dz-errormessage]');
          dzErrorMessage.innerHTML = errorMessage;
          this.countSelectedPhotos++;
        },
        success: (file, response) => {
          const id = response.file_id;
          file.id = id;
          this.$emit('success', id, file.dataURL);
        },
        complete: (file) => {
          this.$emit('imageIsLoaded', file.dataUrl);
          file.previewElement.classList.add('dropzone__preview--complete');
        },
        removedfile: (file) => {
          this.removePhoto(file.id);
          if (file.previewElement != null && file.previewElement.parentNode != null) {
            file.previewElement.parentNode.removeChild(file.previewElement);
          }
        },
        thumbnail: (file, dataUrl) => {
          if (file.previewElement) {
            let thumbnailElement;
            const image = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]');
            for (let i = 0; i < image.length; i++) {
              thumbnailElement = image[i];
              thumbnailElement.alt = file.name;
              thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
            }
          }
        },
      },
    };
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    imageSizes() {
      return this.mqIsMobile ? [80, 80] : [110, 110];
    },
  },
  watch: {
    uploadedPhotos: {
      handler() {
        this.setMaxFiles();
      },
      deep: true,
    },
  },
  mounted() {
    this.setMaxFiles();
  },
  methods: {
    template() {
      return `
        <div class="dropzone__preview">
          <div class="dropzone__image" data-dz-thumbnail-bg></div>
          <div class="dropzone__progress">
            <span class="dropzone__upload" data-dz-uploadprogress></span>
          </div>
          <div class="dropzone__error-message" data-dz-errormessage></div>
          <button
            class="dropzone__remove"
            data-dz-remove
            aria-label="Удалить фото"
          />
        </div>
      `;
    },
    removePhoto(id) {
      this.$emit('removedfile', id);
      this.countSelectedPhotos--;
    },
    setMaxFiles() {
      const maxFiles = this.maxCountPhoto - this.uploadedPhotos.length;
      this.$refs.dropzone.dropzone.options.maxFiles = maxFiles;
    },
  },
}
</script>
