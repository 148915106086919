<template>
  <UpdateReturn :info.sync="info" />
</template>

<script>
import UpdateReturn from '@/components/profile/updateReturn';
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'UpdateReturnPage',
  components: {
    UpdateReturn,
  },
  mixins: [pageMeta],
  async asyncData({ $api, route, redirect }) {
    try {
      const response = await $api.profile.getCurrentReturn(route.params.id);

      if (response?.items.length) {
        return {
          pageTitle: `Возврат заказа №${route.params.id}`,
          info: response,
        }
      }

      redirect({
        name: 'profile.order',
      });
    } catch (error) {
      redirect({
        name: 'profile.order',
      });
      console.error(error);
    }
  },
  data() {
    return {
      info: {},
    }
  },
}
</script>
