<template>
  <div
    class="return-update-item"
    :class="{'return-update-item--disabled': !canReturn}"
  >
    <Alert
      v-if="visibleErrorReturnAlert"
      @close="closeErrorReturnAlert()"
    >
      <template #title>
        Недоступно для возврата
      </template>
      <template #content>
        Данный товар не доступен для возврата.
      </template>
    </Alert>

    <Alert
      v-if="visibleErrorCanReturnAlert"
      type="error"
      @close="closeErrorCanReturnAlert()"
    >
      <template #content>
        Товар уже нельзя вернуть, удалите его из возврата
      </template>
    </Alert>

    <nuxt-link
      :to="{
        name: 'catalog.category.product',
        params: {
          category: info.product.category_code,
          product: info.product.code,
        },
      }"
      class="return-update-item__content"
    >
      <CustomImage
        class="return-update-item__picture"
        :image-id="info.product.image.id"
        :alt="info.product.name"
        :width="imageSizes[0]"
        :height="imageSizes[1]"
      />
      <span class="return-update-item__details">
        <span v-if="info.price" class="return-update-item__price">
          {{ getCurrency(info.price) }}
        </span>
        <span class="return-update-item__info">
          {{ info.product.name }}
        </span>
        <span v-if="dimensions" class="return-update-item__info">
          Размер: {{ dimensions }}
        </span>
      </span>
    </nuxt-link>

    <div v-if="canReturn" class="return-update-item__controls">
      <CustomSelect
        v-if="reasonsOptions.length"
        v-model="reason"
        :options="reasonsOptions"
        label-option-name="name"
        style-light
        float
        :error="textErrorReasonId"
        class="return-update-item__control"
        @input="updateItemReturn()"
      >
        Причина возврата
      </CustomSelect>
      <div
        v-if="reason && reason.hint"
        class="return-update-item__hint"
        v-html="reason.hint"
      />
      <CustomInput
        v-model="description"
        class="return-update-item__control"
        tag="textarea"
        rows="1"
        :error="textErrorDescription"
        style-light
        float
        @change="updateItemReturn()"
      >
        Описание проблемы
      </CustomInput>
      <Alert
        v-show="visibleErrorAlert"
        type="error"
        @close="closeErrorAlert()"
      >
        <template #content>
          <p>{{ textErrorAlert }}</p>
        </template>
      </Alert>
      <p class="return-update-item__caption">
        Прикрепить к заявке фотографии
        всех возвращаемых товаров:
      </p>
      <ul class="return-update-item__list">
        <li class="return-update-item__list-item">
          Общий вид заводской упаковки
        </li>
        <li class="return-update-item__list-item">
          Общий вид товара
        </li>
      </ul>
      <ReturnUploadPhotos
        class="return-update-item__add-photo"
        :uploaded-photos="returnPhotos"
        :error="textErrorFiles"
        @success="successUploadPhoto($event)"
        @removedfile="removedPhoto($event)"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { getProductDemensions } from '@/plugins/helpers/product';
import CustomSelect from '@/components/elements/CustomSelect';
import CustomInput from '@/components/elements/CustomInput';
import ReturnUploadPhotos from '@/components/profile/returnUploadPhotos';
import Alert from '@/components/elements/Alert';

import './index.css';
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'ReturnUpdateProductItem',
  components: {
    CustomImage,
    CustomSelect,
    CustomInput,
    Alert,
    ReturnUploadPhotos,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      reason: null,
      description: this.info.reason_description,
      reasonsOptions: [],
      files: Array.from(this.info.return_item_photo, item => item.id),
      returnPhotos: this.info.return_item_photo,
      visibleErrorAlert: false,
      textErrorAlert: 'Произошла ошибка при выполнении операции',
      canReturn: this.info.can_return,
      visibleErrorReturnAlert: !this.info.can_return,
    };
  },
  computed: {
    ...mapGetters('currency', { getCurrency: 'getCurrency' }),
    imageSizes() {
      return this.mqIsMobile ? [80, 80] : [110, 110];
    },
    dimensions() {
      const dimensions = getProductDemensions(this.info.product);
      return dimensions?.value;
    },
    textErrorReasonId() {
      const reason = this.$v.reason;
      let text = '';
      if (reason.$dirty && reason.$invalid) {
        text = 'Укажите причину возврата';
      }
      return text;
    },
    textErrorDescription() {
      const description = this.$v.description;
      let text = '';
      if (description.$dirty && description.$invalid) {
        text = 'Укажите описание проблемы';
      }
      return text;
    },
    textErrorFiles() {
      const files = this.$v.files;
      let text = '';
      if (files.$dirty && files.$invalid) {
        text = 'Загрузите фотографии';
      }
      return text;
    },
    visibleErrorCanReturnAlert() {
      const canReturn = this.$v.canReturn;
      return canReturn.$dirty && canReturn.$invalid;
    },
    isValid() {
      return !this.$v.$invalid;
    },
  },
  created() {
    this.getReasonsReturn();
  },
  validations() {
    return {
      reason: {
        required,
      },
      description: {
        required: requiredIf(() => {
          return this.reason?.is_required_comment ?? false;
        }),
      },
      files: {
        required: requiredIf(() => {
          return this.reason?.is_required_photo ?? false;
        }),
      },
      canReturn: {
        sameAs: sameAs(() => true),
      },
    };
  },
  methods: {
    async getReasonsReturn() {
      const { items } = await this.$api.profile.getReasonsReturn(this.info.id);
      this.reasonsOptions = items;
      if (this.info.reason.id) {
        this.reason = this.reasonsOptions.find(item => item.id === this.info.reason.id);
      }

    },
    async updateItemReturn() {
      try {
        const data = {
          files: this.files,
          return_reason_id: this.reason?.id || null,
          return_reason_description: this.description,
        }
        await this.$api.profile.updateItemReturn(this.$route.params.id, this.info.id, data);
        this.visibleErrorAlert = false;
      } catch (error) {
        if (error.response?.status === 422) {
          this.$v.reason.$touch();
        } else {
          this.visibleErrorAlert = true;
        }
        console.error(error);
      }
    },
    closeErrorAlert() {
      this.visibleErrorAlert = false;
    },
    successUploadPhoto(id) {
      this.files.push(id);
      this.updateItemReturn();
    },
    removedPhoto(id) {
      this.files = this.files.filter((item) => item !== id);
      this.returnPhotos = this.returnPhotos.filter((item) => item.id !== id);
      this.updateItemReturn();
    },
    validation() {
      this.$v.$touch();
    },
    closeErrorReturnAlert() {
      this.visibleErrorReturnAlert = false;
    },
    closeErrorCanReturnAlert() {
      this.$v.canReturn.$reset();
    },
  },
}
</script>
