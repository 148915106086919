<template>
  <div class="loader-spinner" :class="loaderTheme" :style="style"></div>
</template>

<script>
export default {
  name: 'LoaderSpinnerComponent',
  props: {
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  computed: {
    style() {
      const style = {}

      this.width && (style.width = this.width)
      this.height && (style.height = this.height)

      return style
    },
    loaderTheme() {
      return `loader-spinner--${this.theme}`
    },
  },
};
</script>
