<template>
  <section class="update-return">
    <div class="update-return__top">
      <p class="update-return__caption font font_title-m font_bold font_uppercase">
        Выбранные товары
      </p>
      <nuxt-link :to="{ name: 'profile.order.return' }" class="update-return__link-edit">
        Изменить состав
      </nuxt-link>
    </div>
    <ul class="update-return__products">
      <li
        v-for="returnPosition in info.items"
        :key="returnPosition.id"
        class="update-return__product"
      >
        <ReturnUpdateItemPosition
          :ref="`position-${returnPosition.id}`"
          :info="returnPosition"
        />
      </li>
    </ul>
    <div class="update-return__footer">
      <Alert
        v-show="visibleErrorAlert"
        type="error"
        @close="closeErrorAlert()"
      >
        <template #content>
          <p>{{ textErrorAlert }}</p>
        </template>
      </Alert>

      <Button
        class="update-return__button"
        :disabled="isWaitingSetReturn"
        @click="submit"
      >
        <Spinner v-if="isWaitingSetReturn" theme="simple" width="36px" height="36px" show />
        <template v-else>
          Оформить возврат
        </template>
      </Button>
      <Button
        :to="{
          name: 'profile.order',
          params: {
            id: $route.params.id,
          }
        }"
        theme="tertiary"
        class="update-return__button"
      >
        Назад к заказу
      </Button>
    </div>
  </section>
</template>

<script>
import ReturnUpdateItemPosition from '@/components/profile/returnUpdateItemPosition';
import Alert from '@/components/elements/Alert';
import Button from '@/components/elements/Button';
import './index.css';
import Spinner from '~/components/elements/LoaderSpinner';

export default {
  name: 'UpdateReturn',
  components: {
    Spinner,
    ReturnUpdateItemPosition,
    Alert,
    Button,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      textErrorAlert: 'Произошла ошибка при выполнении операции',
      visibleErrorAlert: false,
      isWaitingSetReturn: false,
    };
  },
  methods: {
    async submit() {
      const positions = Object.keys(this.$refs).filter((key) => key.includes('position'));
      positions.forEach((key) => this.$refs[key][0].validation());
      const isValid = positions.every((key) => this.$refs[key][0].isValid);
      if (isValid) {
        this.isWaitingSetReturn = true
        const responseRoute = await this.$api.profile.setReturnRoute(this.$route.params.id);
        this.isWaitingSetReturn = false
        this.$router.push({
          name: 'profile.return.create-return',
          params: { id: responseRoute?.return_id },
        });
      }
    },
    closeErrorAlert() {
      this.visibleErrorAlert = false;
    },
  },
}
</script>
